/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import MainForm from "./MainForm/MainForm";
import IntroPage from "./IntroPage/IntroPage";
import SuccessPage from "./SuccessPage/SuccessPage";

import { questionnaire } from './DataFiles/formArrays';

import './App.css';

const App = () => {
  const [formData, setFormData] = useState({});
  const [onIntro, setOnIntro] = useState(true);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const goToForm = () => {
   navigate('/form');
    setOnIntro(false);
  }

  useEffect(() => {
    if (onIntro) {
      navigate('/');
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        {onIntro ?
          <Route
            exact
            path='/'
            element={
              <IntroPage
                goToForm={goToForm}
                formData={formData}
                setFormData={setFormData}
              />
            }
          />
        :
          <>
            <Route
              path='/form'
              element={
                <MainForm
                  formData={formData}
                  setFormData={setFormData}
                  questionnaire={questionnaire}
                  page={page}
                  setPage={setPage}
                />
              }
            />
            <Route
              path='/success'
              element={
                <SuccessPage />
              }
            />
          </>
        }
      </Routes>
    </div>
  );
}

export default App;
